import * as React from "react"


import "../css/index.css";

const PrivacyPage = () => (
  <>
    <h4>Tara IT AB Privacy Policy</h4>
    <p>This privacy policy will explain how our organization uses the personal data we collect from you when you use our website and services.</p>

    <h4>What data do we collect?</h4>
    <p>Tara IT AB collects the following data:</p>
    <ul>
      <li>Personal identification information (Name, email address, phone number, etc.)</li>
      <li>Communication transcripts (Email conversations)</li>
    </ul>

    <h4>How do we collect your data?</h4>
    <p>You directly provide Tara IT AB with most of the data we collect. We collect data and process data when you:</p>
    <ul>
      <li>Voluntarily use or view our website via your browser’s cookies.</li>
      <li>Voluntarily submit a contact form on our website.</li>
      <li>Voluntarily send an e-mail to any of our e-mail adresses ending with @tarait.se</li>
      <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
    </ul>

    <h4>How will we use your data?</h4>
    <p>Tara IT AB collects your data so that we can:</p>
    <ul>
      <li>Contact you via email or phone if you filled in the contact request form on our website.</li>
      <li>Email you with special offers on other products and services we think you might like.</li>
      <li>Analyze website statistics such as number of visitors, time spent on our website, etc.</li>
    </ul>
    <h4>How do we store your data?</h4>
    <p>Tara IT AB securely stores your data in our data storage.
    Tara IT AB will keep your personal identification information up to 6 months. Once this time period has expired, we will delete your data from the data storage.</p>

    <h4>What are your data protection rights?</h4>
    <p>Tara IT AB would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
    <ul><li>The right to access – You have the right to request Tara IT AB for copies of your personal data. We may charge you a small fee for this service.</li>
    <li>The right to rectification – You have the right to request that Tara IT AB correct any information you believe is inaccurate. You also have the right to request Tara IT AB to complete the information you believe is incomplete.</li>
    <li>The right to erasure – You have the right to request that Tara IT AB erase your personal data, under certain conditions.</li>
    <li>The right to restrict processing – You have the right to request that Tara IT AB restrict the processing of your personal data, under certain conditions.</li>
    <li>The right to object to processing – You have the right to object to Tara IT AB’s processing of your personal data, under certain conditions.</li>
    <li>The right to data portability – You have the right to request that Tara IT AB transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
    </ul>
    <h4>Cookies</h4>
    <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology
    For further information, visit allaboutcookies.org.
</p>
    <h4>How do we use cookies?</h4>
    <p>Tara IT AB uses cookies in a range of ways to improve your experience on our website and understanding how you use our website.</p>
    <h4>How to manage cookies</h4>
    <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>

    <h4>Changes to our privacy policy</h4>
    <p>Tara IT AB keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 27 April 2021.</p>

    <h4>How to contact us</h4>
    <p>If you have any questions about Tara IT AB’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
Email us at: hi@tarait.se</p>
    <h4>How to contact the appropriate authority</h4>
    <p>Should you wish to report a complaint or if you feel that Tara IT AB has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office (Integritetsskyddsmyndigheten).</p>

  </>
)

export default PrivacyPage